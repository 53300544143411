<template>
  <validation-provider
    #default="{ errors }"
    name="CEP"
    :rules="rules"
  >
    <b-input-group>
      <masked-input
        :id="id"
        ref="cep"
        v-model="valueIn"
        :mask="cepMask"
        class="form-control"
        :class="{'is-invalid' : errors.length > 0}"
        @input="sendToParent()"
        @blur.native="checarCep()"
      />
      <b-input-group-append>
        <b-button
          v-b-tooltip.hover
          title="Buscar endereço completo pelo CEP"
          variant="primary"
          class="btn-sm"
          @click="buscarEndereco()"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
/* eslint-disable prefer-destructuring */

import estadoApi from '@pilar/components/estado-select/api'
import municipioApi from '@pilar/components/municipio-select/api'
import { ValidationProvider } from 'vee-validate'

import {
  required,
} from '@validations'

import {
  is,
} from '@pilar/plugins/p-validation/p-validation'

import {
  cep as cepMask,
} from '@pilar/mixins/mascaras'

export default {
  name: 'CepInput',
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    obrigatorio: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      is,
      required,
      cepMask,
      endereco: {},
      valueIn: {},
      cepInvalido: false,
      rules: '',
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueIn = value
      }
    },
  },
  created() {
    this.valueIn = this.value
    this.buildRules()
  },
  methods: {
    buildRules() {
      let rules = ''
      rules = this.obrigatorio ? 'required' : ''
      // eslint-disable-next-line eqeqeq
      rules = `${rules + (!rules ? '' : '|')}is:${!this.cepInvalido}`
      this.rules = rules
    },
    validarCep(cep) {
      return !cep || cep.replace(/\D/g, '').length < 8
    },
    async buscarEndereco() {
      if (this.valueIn !== '') {
        this.cepInvalido = this.validarCep(this.valueIn)

        if (!this.cepInvalido) {
          const cep = this.valueIn.replace(/\D/g, '')
          const dados = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
          const endereco = await dados.json()

          this.cepInvalido = endereco.erro
          this.buildRules()
          if (!this.cepInvalido) {
            this.endereco.logradouro = endereco.logradouro
            this.endereco.bairro = endereco.bairro
            this.endereco.complemento = endereco.complemento

            const estados = await estadoApi.getEstados()
            const estado = estados.data.find(f => f.sigla === endereco.uf)
            this.endereco.uf = {
              ibge: estado.id,
              sigla: estado.sigla,
            }
            const municipio = await municipioApi.getMunicipio(endereco.ibge)
            this.endereco.municipio = { ibge: municipio.data.id, nome: municipio.data.nome }

            this.$emit('endereco', this.endereco)
          }
        } else {
          this.buildRules()
        }
      }
    },
    sendToParent() {
      this.$emit('input', this.valueIn)
    },
    checarCep() {
      if (this.valueIn) {
        const cep = this.valueIn.replace(/\D/g, '')
        if (cep.length === 8) {
          fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(test => test.json()).then(response => {
              this.cepInvalido = response.erro
              this.buildRules()
            })
        } else {
          this.cepInvalido = true
          this.buildRules()
        }
      }
    },
  },
}
</script>
