import axios from 'axios'

const PREFIX = 'https://servicodados.ibge.gov.br/api/v1/localidades/'

export default {

  getMunicipiosPorUf(uf) {
    const uninterceptedAxiosInstance = axios.create()
    return uninterceptedAxiosInstance.get(`${PREFIX}estados/${uf}/municipios`)
  },

  getMunicipios() {
    const uninterceptedAxiosInstance = axios.create()
    return uninterceptedAxiosInstance.get(`${PREFIX}municipios`)
  },

  async getMunicipio(ibge) {
    const uninterceptedAxiosInstance = axios.create()
    return uninterceptedAxiosInstance.get(`${PREFIX}municipios/${ibge}`)
  },
}
