import axios from 'axios'

const PREFIX = 'https://servicodados.ibge.gov.br/api/v1/localidades/'

export default {
  async getEstados() {
    const uninterceptedAxiosInstance = axios.create()
    return uninterceptedAxiosInstance.get(`${PREFIX}estados`)
  },
  getEstado(ibge) {
    const uninterceptedAxiosInstance = axios.create()
    return uninterceptedAxiosInstance.get(`${PREFIX}estados/${ibge}`)
  },
}
